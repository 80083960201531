import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  SectionHelper,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [connection, setConnection] = React.useState({} as any);
  const [isRequestedRefresh, setIsRequestedRefresh] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/one-threads/_functions", // Replace with your API base URL
      applicationID: "7ee9bead-5d3a-4a56-8e02-279b7aa88b0f", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getConnection();
  }, []);

  const getConnection = () => {
    fetch("https://certifiedcode.wixsite.com/one-threads/_functions/connection", {
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setConnection(res);
      });
  };

  const updateCount = () => {
    setIsRequestedRefresh(true);
    fetch("https://certifiedcode.wixsite.com/one-threads/_functions/count", {
      method: "POST",
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      });
  };

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="One: Threads Followers Count"
                content="To use this widget, go to Editor > Add Panel (+) > App Widgets > One: Threads Followers Count"
                actions={
                  <Box gap="SP2" direction="vertical">
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.Edit />}
                        as="a"
                        href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                        target="_blank"
                      >
                        Edit Site
                      </Button>
                      <Button
                        suffixIcon={<Icons.PremiumFilled />}
                        skin={isUpgraded ? "premium-light" : "premium"}
                        as="a"
                        href={basePlatform.getUpgradeUrl()}
                        target="_blank"
                      >
                        {isUpgraded ? "Manage Plan" : "Upgrade to set live"}
                      </Button>
                    </Box>
                    {connection?.isConnected && (
                      <SectionHelper
                        title={
                          connection?.isConnected
                            ? `Connected to ${connection?.meta?.username} (@${connection?.meta?.username})`
                            : "Not Connected"
                        }
                        appearance="success"
                      >
                        <Box gap="SP2" direction="vertical">
                        {isUpgraded
                          ? "Followers count updating every hour."
                          : "Followers count updating every 24 hours. Upgrade to refresh now."}
                        {isRequestedRefresh ? (
                          <Button disabled>You've requested a refresh</Button>
                        ) : (
                          <Button
                            disabled={!isUpgraded}
                            onClick={() => {
                              if (isUpgraded) {
                                updateCount();
                              } else {
                                window.open(
                                  basePlatform.getUpgradeUrl(),
                                  "_blank"
                                );
                              }
                            }}
                          >
                            Refresh now
                          </Button>
                        )}
                        </Box>
                      </SectionHelper>
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
